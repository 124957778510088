.exposure-block {
  position: relative;
  margin-top: 40px;
  padding: 24px 40px 40px 24px;
  min-height: 422px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid $gray-4;
  background: $gray-1;
  &__header {
    position: absolute;
    top: 24px;
    left: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &__download-icon {
    display: flex;
    align-items: center;
  }
  &__checked_at {
    position: absolute;
    left: 24px;
    bottom: 24px;
    color: $gray-7;
    font-size: 12px;
    line-height: 16px;
  }
  &__data {
    padding-right: 8px;
    padding-bottom: 40px;
    margin-top: 60px;
    width: 460px;
    height: fit-content;
  }
  &__chart {
    margin-top: 24px;
    height: 325px;
    display: flex;
    align-items: center;
    position: relative;
    &__arrow {
      position: absolute;
      right: calc(100% - 15px);
      bottom: 16px;
      &.revert {
        right: auto;
        left: calc(100% - 15px);
      }
    }
    &__error {
      position: absolute;
      right: calc(100% - 15px);
      bottom: 16px;
      &.revert {
        right: auto;
        left: calc(100% - 15px);
      }
    }
  }
  &__indirect-exposure-img {
    margin-top: 16px;
    margin-left: 16px;
    width: 400px;
    height: 325px;
  }
  &__item {
    height: 32px;
    width: 100%;
    padding: 5px 16px;
    font-size: 12px;
    line-height: 22px;
    color: $gray-8;
    display: flex;
    justify-content: space-between;
    span {
      color: $gray-9;
      font-size: 14px;
      line-height: 22px;
    }
    &:not(:first-child) {
      border-top: 1px solid $gray-3;
    }
    &.indirect-exposure {
      &:not(:first-child) {
        border-top: 1px solid $gray-3;
      }
    }
  }
  &__identified {
    margin-top: 24px;
    padding: 28px 12px 24px 12px;
    border-radius: 8px;
    background: $gray-2;
    &__title {
      margin-left: 16px;
      margin-bottom: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
  &__empty {
    height: 264px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.loading, &.error  {
      height: 150px;
    }
  }
  &__laoding {
    color: $gray-8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    width: 162px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &__error {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $gray-8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &__btn {
      margin-top: 16px;
      width: 282px;
      height: 40px;
      border-radius: 4px;
    }
  }
  &__not-checked, &__not-supported {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
      color: $gray-8;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    &__btn {
      margin-top: 16px;
      width: 282px;
      height: 40px;
      color: $gray-1;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  &__not-supported {
    &__title {
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .exposure-block {
    margin-top: 24px;
    &__data {
      margin-top: 60px;
      width: 324px;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .exposure-block {
    min-height: 300px;
    padding: 24px 8px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    background-color: transparent;
    &__header {
      top: 27px;
      left: 16px;
    }
    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    &__download-icon {
      display: none;
    }
    &__checked_at {
      bottom: unset;
      left: unset;
      right: 24px;
      top: 30px;
    }
    &__data {
      margin-top: 24px;
      width: 100%;
      padding-right: 0;
    }
    &__identified {
      margin-top: 12px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 16px 0 16px 0;
      &__title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .exposure-block__item:first-child {
        margin-top: 0;
      }
    }

    &__item:first-child {
      margin-top: 26px;
    }
    &__empty {
      &.loading, &.error {
        height: 184px;
      }
    }
    &__not-checked, &__not-supported {
      height: 300px;
    }
    &__chart {
      margin-right: 0;
      height: 336px;
      width: 312px;
    }
  }
}