.transfers-details {
  &-main {
    padding: 24px 24px 40px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid $gray-4;
    background: $gray-1;
    &__header {
      margin-bottom: 38px;
      width: 460px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__main {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
      }
      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }
      &__sub-title {
        font-size: 14px;
        line-height: 22px;
      }
      &__additional {
        flex-direction: row;
        display: flex;
        align-items: center;
      }
      &__notes {
        margin-left: 8px;
        circle {
          fill: $gray-4;
        }
        path {
          fill: $gray-8;
        }
      }
      &__menu {
        margin-left: auto;
        transform: rotate(90deg);
        &__trigger {
          display: inline-block;
          width: 100%;
          svg, path {
            fill: $pink;
          }
        }
      }
    }
    &__left, &__right {
      width: 460px;
      .alerts-detail-item:first-child {
        margin-top: 0;
      }
    }
    &__right > .alerts-detail-item:last-child,
    &__left > .alerts-detail-item:last-child {
      border-bottom: none;
    }
    .alerts-detail-item {
      width: 100%;
    }
  }
  &-alerts {
    position: relative;
    min-height: 240px;
    margin-top: 48px;
    padding-top: 24px;
    padding-bottom: 32px;
    border-radius: 8px;
    border: 1px solid $gray-4;
    background: $gray-1;
    &.empty {
      position: relative;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      padding-left: 40px;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }
    &__risk-title {
      color: $gray-7;
      margin-right: 24px;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;

      &--empty {
        position: absolute;
        top: 26px;
        right: 0;
      }
    }
    .alerts-table {
      border: none;
      border-radius: 0;
    }
    .alerts-show-more {
      margin-top: 0;
    }
    .alerts-table__item:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled{
  pointer-events: none;
  cursor: default;
}
.ant-dropdown-menu-title-content > a:hover {
  color: var(--accent-main, #748ADA);
}
.ant-dropdown-menu-title-content:hover {
  color: var(--accent-main, #748ADA);
}

@media screen and (max-width: $max-md-media) {
  .transfers-details {
    &-main {
      &__header, &__left, &__right {
        width: 100%;
        .alerts-detail-item:first-child {
          margin-top: 0;
        }
      }
      &__left {
        .alerts-detail-item:last-child {
          border-bottom: 1px solid $gray-3;
        }
      }
      &__right {
        margin-top: 24px;
      }
    }
    .alerts-table__wrapper {
      width: auto;
      overflow: scroll;
    }
    .alerts-table {
      margin-top: 0;
      width: 996px;
    }
    &-alerts {
      padding-top: 0;
      border-radius: 0;
      border: none;
      background: transparent;
      &.empty {
        max-width: 720px;
        width: 100%;
        margin-left: 80px;
      }
      &__header {
        max-width: 720px;
        width: 100%;
        margin-left: 80px;
      }
      .alerts-table {
        border: 1px solid $gray-4;;
        border-radius: 8px;
      }
      &__risk-title {
        &--empty {
          top: 0;
        }
      }
      .ps__rail-x {
        border-radius: 4px;
        background: $gray-2;
        height: 10px;
        margin-left: 80px;
        margin-right: calc(100vw - 720px - 80px);
      }
    }
  }
}

@media screen and (max-width: $more-md-media) {
  .transfers-details {
    &-alerts {
      &__header {
        margin-left: auto;
        margin-right: auto;
      }
      &.empty {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .ps__rail-x {
      margin-left: calc((100vw - 720px) / 2);
      margin-right: calc((100vw - 720px) / 2);
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .transfers-details {
    margin-top: 8px;
    &-main-mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      .alerts-detail-item {
        width: 100%;
        &:nth-child(2n+1) {
          margin-top: 0;
        }
      }
    }
    &-alerts {
      margin-top: 40px;
      min-height: unset;
      &__header {
        margin-bottom: 16px;
        padding-left: 24px;
      }
      &__risk-title {
        background: $gray-2;
        padding: 8px 16px;
        margin-right: 8px;
        border-radius: 2px;
        line-height: 16px;
      }
      &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
}
