$opacity: 0.3;
$translation: 70px;

.modal-route-right {
  opacity: $opacity;
  transform: translateX(-$translation);
  animation: slide-in-right 0.7s ease-in forwards;
}

.modal-route-left {
  opacity: $opacity;
  transform: translateX(-$translation);
  animation: slide-in-left 0.7s ease-in forwards;
}

@keyframes slide-in-right {
  0% {
    opacity: $opacity;
    transform: translateX(-$translation);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-in-left {
  0% {
    opacity: $opacity;
    transform: translateX(-$translation);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
